import React, { Component } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Nav } from '../components/nav'
import { NavSmall } from '../components/nav_small'
import '../styles/thankyou.scss'

class Obrigado extends Component {
  render() {
    return (
      <Layout>
        <Helmet title="Barlavento - Obrigado" />

        {/* HEADER */}
        <header>
          <img
            src={require('../assets/logo1.svg')}
            className="logo"
            alt="logo"
          />
        </header>
        <Nav />
        <NavSmall />
        <div className="thankyou">
          <h1 className="thankyou_title">Obrigado!</h1>
          <h3 className="thankyou_text">Em breve alguém da equipe entrará em contato. </h3>
          <h3 className="thankyou_text">Enquanto isso, fique a vontade para se conectar com a Barlavento no nosso <a target='_blank' rel="noopener noreferrer" className='thankyou_text' href="https://www.instagram.com/barlaventoestrategia">Instagram</a> ou pelo nosso <a target='_blank' rel="noopener noreferrer" className="thankyou_text" href="https://medium.com/barlavento">Medium</a>.</h3>
        </div>

      </Layout>
    )
  }
}

export default Obrigado
